import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery20Icon from "@mui/icons-material/Battery20";
import Battery50Icon from "@mui/icons-material/Battery50";
import Battery80Icon from "@mui/icons-material/Battery80";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import { Grid, Stack, Typography } from "@mui/material";

interface Props {
  batteryIndicator: number;
}

const HandheldBattery = (props: Props) => {
  const getBatteryIcon = (battery: number) => {
    if (battery >= 0 && battery < 20) {
      return <Battery0BarIcon />;
    } else if (battery >= 20 && battery < 40) {
      return <Battery20Icon />;
    } else if (battery >= 40 && battery < 60) {
      return <Battery50Icon />;
    } else if (battery >= 60 && battery < 80) {
      return <Battery80Icon />;
    } else if (battery >= 80 && battery <= 100) {
      return <BatteryFullIcon />;
    } else {
      return <></>;
    }
  };

  const getBatteryLabel = (battery: number) => {
    if (battery >= 0 && battery < 20) {
      return "<20%";
    } else if (battery >= 20 && battery <= 100) {
      return `${battery}%`;
    } else if (battery === -1) {
      return "";
    } else if (battery === -2) {
      return "not supported";
    } else {
      return "unknown";
    }
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Battery</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {getBatteryIcon(props.batteryIndicator)}
          <Typography noWrap>{getBatteryLabel(props.batteryIndicator)}</Typography>
        </Stack>
      </Grid>
    </>
  );
};

export { HandheldBattery };
