import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { COBRA_API } from "../api/config";
import { DEVICE_STATUS_PAGE } from "../constants/pages";
import {
  setCurrentConnectedDeviceId,
  setRegisteredDevices,
} from "../stores/slices/deviceSlice";
import { RootState } from "../stores/store";

const ConnectToDevicePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const deviceId = searchParams.get("deviceId");

  const userManufacturerId = useSelector(
    (state: RootState) => state.user.manufacturerId
  );
  const userManufacturerName = useSelector(
    (state: RootState) => state.user.manufacturerName
  );
  const registeredDevices = useSelector(
    (state: RootState) => state.device.registeredDevices
  );

  const [currentDeviceId, setCurrentDeviceId] = useState<string | null>(
    deviceId
  );
  const [showAlertDeviceOffline, setShowAlertDeviceOffline] = useState(false);
  const [showAlertForbiddenToConnect, setShowAlertForbiddenToConnect] =
    useState(false);
  const [showAlertInvalidDeviceId, setShowAlertInvalidDeviceId] =
    useState(false);
  const [offlineDeviceId, setOfflineDeviceId] = useState("");

  useEffect(() => {
    if (userManufacturerId) {
      getRegisteredUserDevices();
    } else {
      getRegisteredDevices();
    }
  }, [userManufacturerId]);

  const getRegisteredUserDevices = async () => {
    try {
      const devices = (
        await COBRA_API.DeviceRegistry.getRegisteredUserDevices()
      ).data;
      dispatch(setRegisteredDevices([...devices]));
    } catch (e) {}
  };

  const getRegisteredDevices = async () => {
    try {
      const devices = (await COBRA_API.DeviceRegistry.getRegisteredDevices())
        .data;
      dispatch(setRegisteredDevices([...devices]));
    } catch (e) {}
  };

  const onConnectClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    handleConnect();
  };

  const onEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Enter", "NumpadEnter"].includes(event.key)) {
      event.preventDefault();
      handleConnect();
    }
  };

  const handleConnect = async () => {
    setShowAlertForbiddenToConnect(false);
    setShowAlertInvalidDeviceId(false);
    setShowAlertDeviceOffline(false);

    if (!currentDeviceId) return;
    const deviceId = +currentDeviceId;

    if (!deviceId) {
      setShowAlertInvalidDeviceId(true);
      return;
    }

    const device = registeredDevices.find((d) => d.deviceId == deviceId);

    if (!device || !device.online) {
      setOfflineDeviceId(currentDeviceId);
      setShowAlertDeviceOffline(true);
      return;
    }

    if (userManufacturerId && device.manufacturerId != userManufacturerId) {
      setShowAlertForbiddenToConnect(true);
      return;
    }

    if (device.online && device.deviceId != null) {
      dispatch(setCurrentConnectedDeviceId(device.deviceId));
      navigate(DEVICE_STATUS_PAGE);
    }
  };

  return (
    <>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Connect to device
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <TextField
          label=""
          size="small"
          value={currentDeviceId ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentDeviceId(event.target.value);
          }}
          onKeyDown={onEnterKeyPress}
        />
        <Button variant="contained" onClick={(e) => onConnectClick(e)}>
          Connect
        </Button>
      </Stack>
      {userManufacturerName && (
        <>
          <Typography variant="h5">{`Online-Devices ${userManufacturerName}`}</Typography>
          {registeredDevices.map((d) => (
            <React.Fragment key={d.deviceId}>
              {d.online &&
                d.manufacturerId == userManufacturerId &&
                d.deviceId && (
                  <Box>
                    <Button
                      onClick={() => setCurrentDeviceId(d.deviceId!.toString())}
                      size="small"
                    >
                      <Typography variant="h6" sx={{ mr: 0.5 }}>
                        {d.deviceId!.toString()}
                      </Typography>
                      <AddCircleOutlineIcon />
                    </Button>
                  </Box>
                )}
            </React.Fragment>
          ))}
        </>
      )}

      {showAlertDeviceOffline && (
        <Alert
          severity="error"
          onClose={() => {
            setShowAlertDeviceOffline(false);
          }}
          sx={{ mt: 2 }}
        >
          <AlertTitle>
            Device with id <strong> {offlineDeviceId} </strong> is offline
          </AlertTitle>
          You can give an internet connection on your Cobra 390 system by
          providing a mobile hotspot using your smartphone and the following
          credentials <br /> <strong>Network name:</strong> <br /> Cobra390
          <br /> <strong> Password:</strong> <br />
          87654321
        </Alert>
      )}
      {showAlertForbiddenToConnect && (
        <Alert
          severity="error"
          onClose={() => {
            setShowAlertForbiddenToConnect(false);
          }}
          sx={{ mt: 2 }}
        >
          Forbidden to connect to the device
        </Alert>
      )}

      {showAlertInvalidDeviceId && (
        <Alert
          severity="error"
          onClose={() => {
            setShowAlertInvalidDeviceId(false);
          }}
          sx={{ mt: 2 }}
        >
          Device id is not valid
        </Alert>
      )}
    </>
  );
};

export { ConnectToDevicePage };
