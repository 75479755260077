import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  analogIo: number;
}

const AnalogIoComponent = (props: Props) => {
  const [analogIoDisplayValue, setAnalogIoDisplayValue] = useState("");

  useEffect(() => {
    const analogIoValue = props.analogIo / 10;
    if (analogIoValue > 10) {
      setAnalogIoDisplayValue(">10.0V");
    } else {
      setAnalogIoDisplayValue(analogIoValue.toFixed(1) + "V");
    }
  }, [props.analogIo]);

  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Analog In</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        {analogIoDisplayValue != "" && (
          <>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Typography>{analogIoDisplayValue} </Typography>
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};

export { AnalogIoComponent };
