import { OverridableStringUnion } from "@mui/types";

export enum HandheldConnectionStateEnum {
  NO_TLG = 0,
  SCAN_TLG_OK = 1,
  LEARN_TLG_OK = 2,
  LOGIN_TLG_OK = 3,
  DATA_TLG_OK = 4,
  LOGOUT_TLG_OK = 5,
  LOGIN_WRONG_ZONE = 6,
  LOGIN_WRONG_SN_WRONG_ZONE = 7,
  SCAN_AND_LOGIN_SN_UNKNOWN = 8,
  LEARN_TLG_ERR = 9,
  RSSI_ERR_CNT = 10,
  BONDING_TLG_AFTER_TIMEOUT = 11,
  UNKNOWN_TLG_CMD = 12,
  LOGIN_TLG_UNEXPECTED = 13,
  WRONG_CUSTOMER_ID = 14,
  WRONG_PROT_VERS_1 = 15,
  WRONG_PROT_VERS_2 = 16,
  WRONG_PROJ_CONST_1 = 17,
  WRONG_PROJ_CONST_2 = 18,
  VARIOKEY_BONDING_TLG_OK = 19,
  WRONG_VARIOKEY = 20,
  LEARN_BLOCKED_WITH_ACTIVATED_VARIOKEY = 21,
  VARIOKEY_BLOCKED_WITH_DEACTIVATED_VARIOKEY = 22,
  LOGIN_IN_HS_CONFIG_MODE = 23,
}

export const HandheldConnectionStateMessage: Record<
  HandheldConnectionStateEnum,
  string
> = {
  [HandheldConnectionStateEnum.NO_TLG]: "Handheld not connected",
  [HandheldConnectionStateEnum.SCAN_TLG_OK]: "OK scan",
  [HandheldConnectionStateEnum.LEARN_TLG_OK]: "OK learn",
  [HandheldConnectionStateEnum.LOGIN_TLG_OK]: "OK login",
  [HandheldConnectionStateEnum.DATA_TLG_OK]: "OK data",
  [HandheldConnectionStateEnum.LOGOUT_TLG_OK]: "OK logout",
  [HandheldConnectionStateEnum.LOGIN_WRONG_ZONE]: "login wrong zone",
  [HandheldConnectionStateEnum.LOGIN_WRONG_SN_WRONG_ZONE]:
    "login wrong SN & zone",
  [HandheldConnectionStateEnum.SCAN_AND_LOGIN_SN_UNKNOWN]: "unknown handheld",
  [HandheldConnectionStateEnum.LEARN_TLG_ERR]: "unknown error",
  [HandheldConnectionStateEnum.RSSI_ERR_CNT]: "RSSI error",
  [HandheldConnectionStateEnum.BONDING_TLG_AFTER_TIMEOUT]:
    "cabin switch timeout",
  [HandheldConnectionStateEnum.UNKNOWN_TLG_CMD]: "unknown handheld cmd",
  [HandheldConnectionStateEnum.LOGIN_TLG_UNEXPECTED]: "unexpected login",
  [HandheldConnectionStateEnum.WRONG_CUSTOMER_ID]: "wrong custom id",
  [HandheldConnectionStateEnum.WRONG_PROT_VERS_1]: "wrong protocol",
  [HandheldConnectionStateEnum.WRONG_PROT_VERS_2]: "wrong protocol",
  [HandheldConnectionStateEnum.WRONG_PROJ_CONST_1]: "wrong constant",
  [HandheldConnectionStateEnum.WRONG_PROJ_CONST_2]: "wrong constant",
  [HandheldConnectionStateEnum.VARIOKEY_BONDING_TLG_OK]: "OK variokey bonding",
  [HandheldConnectionStateEnum.WRONG_VARIOKEY]: "wrong variokey",
  [HandheldConnectionStateEnum.LEARN_BLOCKED_WITH_ACTIVATED_VARIOKEY]:
    "manual pairing deactivated",
  [HandheldConnectionStateEnum.VARIOKEY_BLOCKED_WITH_DEACTIVATED_VARIOKEY]:
    "variokey deactivated",
  [HandheldConnectionStateEnum.LOGIN_IN_HS_CONFIG_MODE]:
    "login open handheld connection",
};

type ConnectionStateColor = OverridableStringUnion<
  "default" | "error" | "success"
>;

export const HandheldConnectionStateColor: Record<
  HandheldConnectionStateEnum,
  ConnectionStateColor
> = {
  [HandheldConnectionStateEnum.NO_TLG]: "default",
  [HandheldConnectionStateEnum.SCAN_TLG_OK]: "success",
  [HandheldConnectionStateEnum.LEARN_TLG_OK]: "success",
  [HandheldConnectionStateEnum.LOGIN_TLG_OK]: "success",
  [HandheldConnectionStateEnum.DATA_TLG_OK]: "success",
  [HandheldConnectionStateEnum.LOGOUT_TLG_OK]: "success",
  [HandheldConnectionStateEnum.LOGIN_WRONG_ZONE]: "error",
  [HandheldConnectionStateEnum.LOGIN_WRONG_SN_WRONG_ZONE]: "error",
  [HandheldConnectionStateEnum.SCAN_AND_LOGIN_SN_UNKNOWN]: "error",
  [HandheldConnectionStateEnum.LEARN_TLG_ERR]: "error",
  [HandheldConnectionStateEnum.RSSI_ERR_CNT]: "error",
  [HandheldConnectionStateEnum.BONDING_TLG_AFTER_TIMEOUT]: "error",
  [HandheldConnectionStateEnum.UNKNOWN_TLG_CMD]: "error",
  [HandheldConnectionStateEnum.LOGIN_TLG_UNEXPECTED]: "error",
  [HandheldConnectionStateEnum.WRONG_CUSTOMER_ID]: "error",
  [HandheldConnectionStateEnum.WRONG_PROT_VERS_1]: "error",
  [HandheldConnectionStateEnum.WRONG_PROT_VERS_2]: "error",
  [HandheldConnectionStateEnum.WRONG_PROJ_CONST_1]: "error",
  [HandheldConnectionStateEnum.WRONG_PROJ_CONST_2]: "error",
  [HandheldConnectionStateEnum.VARIOKEY_BONDING_TLG_OK]: "success",
  [HandheldConnectionStateEnum.WRONG_VARIOKEY]: "error",
  [HandheldConnectionStateEnum.LEARN_BLOCKED_WITH_ACTIVATED_VARIOKEY]: "error",
  [HandheldConnectionStateEnum.VARIOKEY_BLOCKED_WITH_DEACTIVATED_VARIOKEY]:
    "error",
  [HandheldConnectionStateEnum.LOGIN_IN_HS_CONFIG_MODE]: "default",
};
